// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-accessories-js": () => import("./../../../src/pages/en/accessories.js" /* webpackChunkName: "component---src-pages-en-accessories-js" */),
  "component---src-pages-en-blog-js": () => import("./../../../src/pages/en/blog.js" /* webpackChunkName: "component---src-pages-en-blog-js" */),
  "component---src-pages-en-customer-service-js": () => import("./../../../src/pages/en/customer-service.js" /* webpackChunkName: "component---src-pages-en-customer-service-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-inspiration-js": () => import("./../../../src/pages/en/inspiration.js" /* webpackChunkName: "component---src-pages-en-inspiration-js" */),
  "component---src-pages-en-kits-js": () => import("./../../../src/pages/en/kits.js" /* webpackChunkName: "component---src-pages-en-kits-js" */),
  "component---src-pages-en-services-js": () => import("./../../../src/pages/en/services.js" /* webpackChunkName: "component---src-pages-en-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-it-accessori-js": () => import("./../../../src/pages/it/accessori.js" /* webpackChunkName: "component---src-pages-it-accessori-js" */),
  "component---src-pages-it-assistenza-clienti-js": () => import("./../../../src/pages/it/assistenza-clienti.js" /* webpackChunkName: "component---src-pages-it-assistenza-clienti-js" */),
  "component---src-pages-it-blog-js": () => import("./../../../src/pages/it/blog.js" /* webpackChunkName: "component---src-pages-it-blog-js" */),
  "component---src-pages-it-index-js": () => import("./../../../src/pages/it/index.js" /* webpackChunkName: "component---src-pages-it-index-js" */),
  "component---src-pages-it-ispirazioni-js": () => import("./../../../src/pages/it/ispirazioni.js" /* webpackChunkName: "component---src-pages-it-ispirazioni-js" */),
  "component---src-pages-it-kit-js": () => import("./../../../src/pages/it/kit.js" /* webpackChunkName: "component---src-pages-it-kit-js" */),
  "component---src-pages-it-servizi-js": () => import("./../../../src/pages/it/servizi.js" /* webpackChunkName: "component---src-pages-it-servizi-js" */),
  "component---src-templates-blog-article-js": () => import("./../../../src/templates/blog-article.js" /* webpackChunkName: "component---src-templates-blog-article-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */)
}

