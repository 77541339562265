import "./src/styles/global.css";
import React from "react";
import { EmailProvider } from "./src/components/context/email-context";
import { StoreProvider } from "./src/context/store-context";
import { LanguageProvider } from "./src/context/language-context";

export const wrapRootElement = ({ element }) => (
  <EmailProvider>
    <StoreProvider>{element}</StoreProvider>
  </EmailProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <LanguageProvider {...props}>{element}</LanguageProvider>
);
