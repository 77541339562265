import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

const defVals = {
  locale: "it",
  t: {},
};

// does not include the default italian one
const supportedLocale = new Set(["en"]);

export const LanguageContext = React.createContext(defVals);

export const LanguageProvider = ({ children, location }) => {
  const possibleLocale = location.pathname.split("/")[1];
  const locale =
    possibleLocale && supportedLocale.has(possibleLocale)
      ? possibleLocale
      : "it";

  const data = useStaticQuery(graphql`
    query Translations {
      t: allStoryblokEntry(filter: { slug: { eq: "traduzioni" } }) {
        nodes {
          content
          lang
        }
      }
    }
  `);

  const [trads, setTrads] = useState({ it: {}, en: {} });

  useEffect(() => {
    const tr = data.t.nodes.map((n) => [
      n.lang === "default" ? "it" : "en",
      JSON.parse(n.content).trads,
    ]);

    let res = {};
    tr.forEach((t) => {
      res[t[0]] = t[1].reduce((obj, kv) => {
        obj[kv.key] = kv.value;
        return obj;
      }, {});
    });

    setTrads(res);
  }, [data]);

  return (
    <LanguageContext.Provider value={{ locale, t: trads[locale] }}>
      {/* TODO : meta tags for language */}
      {children}
    </LanguageContext.Provider>
  );
};
