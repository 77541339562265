import * as React from "react";
import fetch from "isomorphic-fetch";
import Client from "shopify-buy";
import { graphql, useStaticQuery } from "gatsby";

const client = Client.buildClient(
  {
    domain: "calchitaliani.shop", //"calchi-italiani.myshopify.com",
    storefrontAccessToken: "109dbf6d42b2667f0c6bf5df168fa1ca",
  },
  fetch
);

const clientEng = Client.buildClient(
  {
    domain: "calchitaliani.shop", //"calchi-italiani.myshopify.com",
    storefrontAccessToken: "109dbf6d42b2667f0c6bf5df168fa1ca",
    language: "en",
  },
  fetch
);

const defaultValues = {
  isCartOpen: false,
  hasMessaggio: false,
  isCompensa: false,
  setCart: (newState) => {},
  loading: false,
  addVariantToCart: () => {},
  addVariants: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  tryApplyDiscount: () => {},
  removeDiscount: () => {},
  setMessaggioAndRegalo: (hasR, hasM, msg) => {},
  setCompensa: (val) => {},
  client,
  checkout: {
    lineItems: [],
  },
  upsells: [],
  translatedProds: {},
};

export const StoreContext = React.createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

const COMPENSA_ID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDk4MDQ3MDc1OTYyMQ==";
const REGALA_ID =
  "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80MDk4MDQ3MTg0MDk2NQ==";

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false);
  const [isCartOpen, setCart] = React.useState(false);
  const [hasMessaggio, setHasMessaggio] = React.useState(false);
  const [isCompensa, setIsCompensa] = React.useState(false);
  const [translatedProds, setTranslatedProds] = React.useState({});

  // react to checkout updates
  React.useEffect(() => {
    // handle Regalo and Compensa
    let isR = false,
      isC = false;
    (checkout?.lineItems ?? []).forEach((li) => {
      if (li?.variant?.product?.handle === "compensa") {
        isC = true;
      } else if (li?.variant?.product?.handle === "regala") {
        isR = true;
      }
    });
    setHasMessaggio(isR);
    setIsCompensa(isC);
  }, [checkout]);

  // const allProducts = useStaticQuery(graphql`
  //   query ProdsId {
  //     allShopifyProduct {
  //       nodes {
  //         handle
  //         title
  //         storefrontId
  //         images {
  //           des: gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
  //         }
  //         storefrontId
  //         variants {
  //           storefrontId
  //           title
  //           price
  //         }
  //         collections {
  //           handle
  //         }
  //       }
  //     }
  //   }
  // `).allShopifyProduct.nodes;

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }
    setCheckout(checkout);
  };

  // init or retrieve cart on mount
  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;
      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          );
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }
      const newCheckout = await client.checkout.create();
      setCheckoutItem(newCheckout);
    };

    const getEngProds = async () => {
      const enProds = await clientEng.product.fetchAll();
      const en = enProds.reduce((obj, p) => {
        obj[p.handle.toLowerCase()] = p;
        return obj;
      }, {});
      setTranslatedProds((old) => ({
        ...old,
        en,
      }));
    };

    initializeCheckout();
    getEngProds();
  }, []);

  const addVariantToCart = (variantId, quantity) => {
    setLoading(true);
    const checkoutID = checkout.id;
    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];
    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
        if (!isCartOpen) {
          setCart(true);
        }
      });
  };

  // expect vars to be array of objects {variantId : String, quantity : int}
  const addVariants = (vars) => {
    setLoading(true);
    const checkoutID = checkout.id;
    const lineItemsToUpdate = vars;
    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
        if (!isCartOpen) {
          setCart(true);
        }
      });
  };

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true);
    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true);
    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ];
    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const setMessaggioAndRegalo = (hasR, hasM, message) => {
    setLoading(true);
    const input = {
      customAttributes: [
        { key: "isRegalo", value: hasR ? "true" : "false" },
        { key: "messaggio", value: hasM ? message : "" },
      ],
    };
    client.checkout.updateAttributes(checkout.id, input).then((c) => {
      setCheckout(c);
      if (hasM) {
        addVariantToCart(REGALA_ID, 1);
      } else {
        const [liRegala] = checkout.lineItems.filter(
          (li) => li?.variant?.id == REGALA_ID
        );
        if (liRegala) {
          removeLineItem(checkout.id, liRegala.id);
        }
      }
    });
  };

  const setCompensa = (shouldAdd) => {
    if (isCompensa === shouldAdd) return;
    if (shouldAdd) {
      addVariantToCart(COMPENSA_ID, 1);
    } else {
      const [liCompensa] = checkout.lineItems.filter(
        (li) => li?.variant?.id == COMPENSA_ID
      );
      if (liCompensa) {
        removeLineItem(checkout.id, liCompensa.id);
      }
    }
  };

  const tryApplyDiscount = (code, callback) => {
    setLoading(true);
    client.checkout.addDiscount(checkout.id, code).then((res) => {
      setCheckout(res);
      setLoading(false);
      typeof callback === "function" && callback();
    });
  };

  const removeDiscount = (callback) => {
    setLoading(true);
    client.checkout.removeDiscount(checkout.id).then((res) => {
      setCheckout(res);
      setLoading(false);
      typeof callback === "function" && callback();
    });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addVariants,
        removeLineItem,
        updateLineItem,
        setMessaggioAndRegalo,
        setCompensa,
        tryApplyDiscount,
        removeDiscount,
        checkout,
        loading,
        didJustAddToCart,
        isCartOpen,
        isCompensa,
        hasMessaggio,
        setCart,
        translatedProds,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
