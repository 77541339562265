import React, { useEffect, useState } from "react";

const defVals = { email: "", setEmail: () => {} };

export const EmailContext = React.createContext(defVals);

const hasLocalStorage =
  typeof window !== `undefined` && typeof localStorage !== "undefined";
const emailKey = "ci-email";

export const EmailProvider = ({ children }) => {
  const [email, setEmail] = useState(
    hasLocalStorage ? localStorage.getItem(emailKey) ?? "" : ""
  );

  useEffect(() => {
    if (hasLocalStorage) {
      try {
        localStorage.setItem(emailKey, email);
      } catch (e) {
        // fail silently
      }
    }
  }, [email]);

  return (
    <EmailContext.Provider value={{ email, setEmail }}>
      {children}
    </EmailContext.Provider>
  );
};
